/*=========================
BASIC CSS 
=========================*/

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
} 

:root {
  --primary: #A40A3C;
}


body,
html {
  margin: 0;
  padding: 0;

}

p {
  margin-top: 0;
  font-weight: 300;
  font-size: 14px;
}

p:last-child {
  margin-bottom: 0 !important;
}

h1 {
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.05;
}

h2 {
  font-weight: 500;
  font-size: 20px;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}


img.loginLogo {
  max-width: 250px;
  margin: auto;
}

div#loginBox {
  min-width: 400px;
  max-width: 500px;
  box-shadow: none;
}

form.form {
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  display: flex;
}


.box_editor {
  padding-bottom: 90px;
}

.card_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
  margin: 0;
}

.card_subtitle {
  color: #B5B5C3;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}


.subtitle {
  color: #9494a1;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
}

.input_group h2 {
  font-weight: 500;
  margin: 0;
}

.box_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.input_row {
  display: flex;
  gap: 8px;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loginContainer {
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.landingContainer {
  flex: 1;
  background: url(../img/login_bg.jpeg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginFormContainer {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.box {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  width: fit-content;
}

button {
  border-radius: 5px !important;
  box-shadow: none !important;
}


.landingLogoContainer {
  background: #ffffffb3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  gap: 10px;
}

.landingLogoContainer h2 {
  font-weight: 700;
  margin: 0;
}

.landingLogoContainer img {
  max-width: 210px;
}

.h1_login {
  color: #001F3F;
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.loginFormContainer .box_content {
  height: 100%;
  justify-content: space-between;
}

.loginFormContainer .box_children {
    height: 100%;
}

.loginButtonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}



.login_input_row {
  display: flex;
  gap: 30px;
  width: 100%;
}

.login_input_row > div {
flex: 1 1 50%;
box-sizing: border-box;
}

div#registerBox {
  box-shadow: none;
}

.RegisterSteps {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.RegisterStepCircle {
  background: var(--primary);
  color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.RegisterStepText {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.RegisterStep {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

div#structure {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
}



#top_bar {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  gap: 10px;
  font-size: 12px;
  z-index: 9999;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #0000001A;
}


div#main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main-content {
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow: auto;
}

div#logo {
  text-align: center;
  padding: 20px 10px 30px;
  font-size: 45px;
  color: #00BCD4;
  text-decoration: none;
}

div#loader {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
}

.loaderHide {
  display: none !important;
}

.menuAccordion {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
}


.menuAccordionItem {
  padding: 0 !important;
  min-height: 0 !important;
  margin-bottom: 5px !important;
  border-radius: 4px !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
  padding: 0 !important;
}

.menuAccordionDetails {
  padding: 0 !important;
}

.submenu_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.menu_button_container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#dashboardIndicators {
  display: flex;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
}

.boxIndicator {
  background: #fff;
  border-radius: 10px;
  padding: 13px;
  max-width: 300px;
  width: 100%;
  box-shadow: 0px 4px 39.5px 0px #0000000D;
}

.boxIndicatorContentInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boxIndicator h2 {
  margin: 0;
  font-size: 13px;
  color: var(--penni-park-purple);
  font-weight: 300;
}

.boxIndicatorContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}

span.indicatorValue {
  font-size: 32px;
  color: var(--penni-park-purple);
  font-weight: 600;
}

span.percentageLabel {
  font-size: 13px;
  color: var(--penni-park-purple);
  font-weight: 300;
}